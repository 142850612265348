






















































import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { v4 as uuid } from "uuid";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";

const modalElements = [
  {
    id: uuid(),
    title: "Статус",
    tooltip: "Статус",
    value: "tmp1",
    type: "select",
    width: 424,
    items: ["На ведомственном хранении", "В стадии проверки", "Получение подтверждено", "Изъяты или утрачены", "Снято с учета"].map((item) => ({
      label: item,
      id: item,
    })),
  },
  {
    id: uuid(),
    title: "Корпус",
    tooltip: "Корпус",
    value: "tmp2",
  },
  {
    id: uuid(),
    title: "Этаж (ярус)",
    tooltip: "Этаж (ярус)",
    value: "tmp3",
  },
  {
    id: uuid(),
    title: "Архивохранилище",
    tooltip: "Архивохранилище",
    value: "tmp4",
  },
  {
    id: uuid(),
    title: "Помещение",
    tooltip: "Помещение",
    value: "tmp5",
  },
  {
    id: uuid(),
    title: "Стеллаж",
    tooltip: "Стеллаж",
    value: "tmp6",
  },
  {
    id: uuid(),
    title: "Шкаф",
    tooltip: "Шкаф",
    value: "tmp7",
  },
  {
    id: uuid(),
    title: "Полка",
    tooltip: "Полка",
    value: "tmp7",
  },
];
export default defineComponent({
  name: "ChangeStatusModal",
  components: {
    FormInputElement,
    SelectRolesCard,
  },
  props: {
    title: {
      type: String,
    },
    yesBtnTitle: {
      type: String,
      default: "Да",
    },
    noBtnTitle: {
      type: String,
      default: "Нет",
    },
    cbOnDelete: {
      type: Function,
      default: () => 1,
    },
  },
  data() {
    return {
      formValues: {
        tmp1: null as null | { id: string; label: string },
      },
      modalElements,
      isLoading: false,
    };
  },
  computed: {
    resultModalElements(): any[] {
      return this.formValues.tmp1?.id === "Получение подтверждено" ? modalElements : modalElements.slice(0, 1);
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    deleteCb() {
      this.isLoading = true;
      setTimeout(() => {
        this.closeModal();
      }, 1000);
    },
  },
});
